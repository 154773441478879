import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Header,
  Spinner,
  Alert,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import ShareButton from './ShareButton';
import { Auth, API } from 'aws-amplify';
import { ParameterRepository, fetchParameters } from './parameters';
import Parameter from './Parameter';
import ParameterGroup from './ParameterGroup';
import { fill } from './model';
import KpiContainer, { Kpi } from './KpiContainer';
import { CognitoUserInterface } from '@aws-amplify/ui-components';

/**
 * Props for the ParameterConfigurator component.
 */
interface ParameterConfiguratorProps {
  manifestId: string;
}

async function getUser(): Promise<string> {
  const {
    username,
  } = (await Auth.currentAuthenticatedUser()) as CognitoUserInterface;

  if (username === undefined) {
    throw new Error(
      'Unable to determine username. Failed to fetch current authenticated user.',
    );
  }

  return username.replace(/^AmazonFederate_/, '');
}

export default function ParameterConfigurator({
  manifestId,
}: ParameterConfiguratorProps): JSX.Element {
  const [
    parameterRepository,
    setParameterRepository,
  ] = useState<ParameterRepository | null>(null);
  const [parameterValues, setParameterValues] = useState<Map<string, number>>(
    new Map(),
  );
  const [kpiValues, setKpiValues] = useState<Kpi[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [modelFamily, setModelFamily] = useState<string>('');
  const [modelVersion, setModelVersion] = useState<string>('');

  useEffect(() => {
    getUser()
      .then(user => fetchParameters(user, manifestId))
      .then(response => {
        const repository = new ParameterRepository(
          response.displaySchema.parameters,
        );
        setParameterRepository(repository);

        const parameterValues = repository.getDefaultValues();
        for (const [name, value] of new URLSearchParams(
          window.location.search,
        ).entries()) {
          if (parameterValues.has(name)) {
            parameterValues.set(name, parseFloat(value));
          }
        }

        setParameterValues(parameterValues);
        setIsLoaded(true);
        setModelFamily(response.family);

        if (response.version) {
          setModelVersion(response.version);
        }
      })
      .catch(err => {
        console.error(err);
        setIsLoaded(true);
        setError(err);
      });
  }, []);

  useEffect(() => {
    interface PredictionApiResponse {
      name: string;
      value: number;
      unit: string;
      description: string;
    }
    const promise = getUser()
      .then(user =>
        API.get('modelApi', `/${manifestId}/outputs`, {
          queryStringParameters: Object.assign(
            { requester: user },
            Object.fromEntries(parameterValues),
          ),
        }),
      )
      .then(res => {
        const kpiResponseArray: Array<PredictionApiResponse> = Object.values(
          res,
        );

        const kpis = kpiResponseArray.map((kpi: PredictionApiResponse) => {
          return {
            name: kpi.name,
            value: Math.round(kpi.value),
            unit: kpi.unit,
          };
        });

        setKpiValues(kpis);
      });

    return () => {
      API.cancel(promise, 'cancelling due to stale parameters');
    };
  }, [parameterValues]);

  if (error != null) {
    return (
      <Alert type="error" header="Could not retrieve parameters">
        {error.message}
      </Alert>
    );
  } else if (!isLoaded || parameterValues.size === 0) {
    return <Spinner size="large" />;
  } else {
    const groups = [];

    const urlParams = new URLSearchParams();

    for (const [group, params] of parameterRepository!.getByGroup()) {
      const parameterComponents = [];

      for (const param of params) {
        const props = {
          name: param.humanName,
          description: param.description,
          inputs: param.inputs.map(input => {
            const selectedValue = parameterValues.get(input.name)!;

            if (selectedValue !== input.default) {
              urlParams.set(input.name, selectedValue.toString());
            }

            return {
              name: input.name,
              values: fill(input.min, input.max, input.step),
              isPercentage: param.isPercentage ?? false,
              default: input.default,
              selectedValue,
              onValueChanged: (value: number) =>
                setParameterValues(prev => {
                  const newValues = new Map(prev);
                  newValues.set(input.name, value);
                  return newValues;
                }),
            };
          }),
        };

        parameterComponents.push(<Parameter {...props} />);
      }

      groups.push(
        <ParameterGroup name={group} parameters={parameterComponents} />,
      );
    }

    const queryString = urlParams.toString();
    history.replaceState(
      null,
      '',
      queryString !== '' ? `?${queryString}` : window.location.pathname,
    );

    const graphDashboardRedirect = function handleRedirectButton() {
      const graphDashboardURL =
        location.hostname === 'localhost'
          ? `http://localhost:1020/${modelFamily}/graph`
          : `https://d1a1cljyhjmmu4.cloudfront.net/${modelFamily}/graph`;

      const urlParams = new URLSearchParams();
      if (parameterRepository != null) {
        for (const paramDefinition of parameterRepository.definitions) {
          const name = paramDefinition.name;
          const humanName = paramDefinition.humanName;
          const inputs = paramDefinition.inputs[0];
          const defaultVal = inputs.default;
          const paramInfo = {
            name: name,
            humanName: humanName,
            default: defaultVal,
            current: parameterValues.get(name),
          };
          urlParams.set(name, JSON.stringify(paramInfo));
        }
      }
      const queryString = urlParams.toString();
      window.open(graphDashboardURL + '?' + queryString);
    };

    return (
      <SpaceBetween size="l">
        <Container
          header={
            <Header variant="h2">
              {modelFamily} {modelVersion === '' ? manifestId : modelVersion}{' '}
              Configurator
            </Header>
          }
        >
          <KpiContainer kpis={kpiValues} />
        </Container>
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <ShareButton />
                  <Button
                    iconName="refresh"
                    onClick={() => {
                      setParameterValues(
                        parameterRepository!.getDefaultValues(),
                      );
                    }}
                  >
                    Reset
                  </Button>
                  <Button iconName="external" onClick={graphDashboardRedirect}>
                    Graph Dashboard
                  </Button>
                </SpaceBetween>
              }
            >
              Configuration
            </Header>
          }
        >
          <SpaceBetween size="s">{groups}</SpaceBetween>
        </Container>
      </SpaceBetween>
    );
  }
}
