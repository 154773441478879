
    import './styles.scoped.css';
    export default {
  "link": "_link_4c84z_747vo_3",
  "weight-bold": "_weight-bold_4c84z_747vo_31",
  "weight-normal": "_weight-normal_4c84z_747vo_35",
  "color-inverted": "_color-inverted_4c84z_747vo_39",
  "font-size-body-s": "_font-size-body-s_4c84z_747vo_43",
  "font-size-body-m": "_font-size-body-m_4c84z_747vo_47",
  "font-size-heading-xs": "_font-size-heading-xs_4c84z_747vo_51",
  "font-size-heading-s": "_font-size-heading-s_4c84z_747vo_55",
  "font-size-heading-m": "_font-size-heading-m_4c84z_747vo_59",
  "font-size-heading-l": "_font-size-heading-l_4c84z_747vo_63",
  "font-size-heading-xl": "_font-size-heading-xl_4c84z_747vo_67",
  "font-size-display-l": "_font-size-display-l_4c84z_747vo_71",
  "icon": "_icon_4c84z_747vo_76"
};
  