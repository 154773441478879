import React from 'react';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Icon from '@amzn/awsui-components-react/polaris/icon';

export default () => {
  return (
    <HelpPanel
      footer={
        <div>
          <h3>
            Learn more <Icon name="external" />
          </h3>
          <ul>
            <li>
              <a href="https://w.amazon.com/bin/view/Main/ARDES/Projects/AnalysisPipeline/DesignConfiguratorPRFAQ">
                PR/FAQ
              </a>
            </li>
            <li>
              <a href="https://w.amazon.com/bin/view/Main/ARDES/Projects/AnalysisPipeline#HDesignConfigurator">
                Technical Documentation
              </a>
            </li>
          </ul>
        </div>
      }
      header={<h2>AR Virtual Systems Design Configurator</h2>}
    >
      <div>
        <h3>What is this tool?</h3>
        <p>
          The Design Configurator is a tool created by{' '}
          <a href="">Concept Simulation</a> to present simulation data
          interactively. It uses a combination of past simulation results and
          machine learning to show how changing floor parameters affects the
          overall KPIs of a solution.
        </p>

        {getSimulationResultsDetail()}

        <h3>How do I use it?</h3>
        <p>
          Change the slider inputs to see how they affect the KPIs at the top in
          real time.
        </p>

        <h3>How do I request one?</h3>
        <p>
          If you're interested in using the Design Configurator to evaluate an
          existing concept, or as part of a new concept simulation, please{' '}
          <a href="https://w.amazon.com/bin/view/Amazon_Robotics/Virtual_Systems/VS-Intake/">
            {' '}
            cut a ticket through the Virtual Systems Intake process
          </a>
          .
        </p>

        <h3>Feedback and bug reports</h3>
        <p>
          Please{' '}
          <a href={createFeedbackTicketLink().toString()}>cut a ticket</a> to
          provide feedback, feature requests or bug reports.
        </p>
      </div>
    </HelpPanel>
  );
};

function createFeedbackTicketLink(): URL {
  const manifestId = window.location.pathname;

  const url = new URL('https://issues.amazon.com/issues/create');
  url.searchParams.append('template', '487b6a13-5791-4e29-b766-e33ce40662be');

  if (manifestId) {
    url.searchParams.append('customFields[string][0][id]', 'Manifest ID');
    url.searchParams.append('customFields[string][0][value]', manifestId);
  }

  return url;
}

// TODO: Use manifest family, version, ML model error metrics to populate simulation results info
//  Related JIRA ticket: https://jira.robotics.a2z.com/browse/CSIM-2765
function getSimulationResultsDetail(): JSX.Element | undefined {
  const manifestId = window.location.pathname.replace('/', '');

  // FIXME: Remove hard coded TASRS model info
  if (manifestId == '7668f87e-8b8e-49a3-aba7-3054ef8f55cc') {
    return (
      <>
        <h3>What are the simulation results based upon?</h3>
        <p>
          The simulation results are based upon{' '}
          <strong>
            version 2.5.1 of the unified model from May 20th, 2022
          </strong>
          . It <strong>does not include non-happy path scenarios</strong>. We{' '}
          expect a <strong>~10% average error rate</strong> for the prediction.
        </p>
        <p>
          Please see{' '}
          <a href="https://quip-amazon.com/w1jlA6h619no/ToteASRS-Version-History">
            this documentation{' '}
          </a>{' '}
          to learn more about the features of this simulation and understand
          what is included in this version.
        </p>

        <p>
          <strong>NOTE</strong>: Projected performance is <strong>not</strong>{' '}
          de-rated to account for operational variability or model fidelity.
        </p>
      </>
    );
  }
}
