
    import './styles.scoped.css';
    export default {
  "tabs-header": "_tabs-header_14rmt_lypoa_5",
  "tabs-tab": "_tabs-tab_14rmt_lypoa_13",
  "tabs-tab-label": "_tabs-tab-label_14rmt_lypoa_21",
  "tabs-tab-link": "_tabs-tab-link_14rmt_lypoa_34",
  "tabs-tab-disabled": "_tabs-tab-disabled_14rmt_lypoa_72",
  "tabs-tab-active": "_tabs-tab-active_14rmt_lypoa_100",
  "tabs-header--variant-default": "_tabs-header--variant-default_14rmt_lypoa_107",
  "root": "_root_14rmt_lypoa_112",
  "tabs": "_tabs_14rmt_lypoa_5",
  "tabs-content-wrapper": "_tabs-content-wrapper_14rmt_lypoa_128",
  "tabs-content": "_tabs-content_14rmt_lypoa_128",
  "tabs-content-active": "_tabs-content-active_14rmt_lypoa_136",
  "tabs-content-inactive": "_tabs-content-inactive_14rmt_lypoa_146"
};
  