/**
 * Given a start, end, and a step, returns an array filled with the stepped range.
 */
export function fill(start: number, end: number, step: number): number[] {
  if (start > end) {
    throw new Error('end must be less than start');
  }
  if (step <= 0) {
    throw new Error('step must be positive');
  }

  const result = [];

  for (let i = end; i >= start; i -= step) {
    result.unshift(parseFloat(i.toFixed(4)));
  }
  if (result[0] > start) {
    result.unshift(parseFloat(start.toFixed(4)));
  }
  return result;
}
