
    import './styles.scoped.css';
    export default {
  "marker": "_marker_1kjc7_6qpzw_3",
  "root": "_root_1kjc7_6qpzw_17",
  "title": "_title_1kjc7_6qpzw_27",
  "list": "_list_1kjc7_6qpzw_31",
  "marker--dimmed": "_marker--dimmed_1kjc7_6qpzw_71",
  "marker--highlighted": "_marker--highlighted_1kjc7_6qpzw_74"
};
  