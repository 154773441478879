
    import './styles.scoped.css';
    export default {
  "content-enter": "_content-enter_gwq0h_ccur0_3",
  "awsui-motion-fade-in": "_awsui-motion-fade-in_gwq0h_ccur0_1",
  "trigger-expanded": "_trigger-expanded_gwq0h_ccur0_25",
  "icon": "_icon_gwq0h_ccur0_39",
  "root": "_root_gwq0h_ccur0_53",
  "expanded": "_expanded_gwq0h_ccur0_70",
  "icon-container": "_icon-container_gwq0h_ccur0_74",
  "trigger": "_trigger_gwq0h_ccur0_25",
  "trigger-default": "_trigger-default_gwq0h_ccur0_89",
  "trigger-footer": "_trigger-footer_gwq0h_ccur0_92",
  "trigger-navigation": "_trigger-navigation_gwq0h_ccur0_95",
  "trigger-container": "_trigger-container_gwq0h_ccur0_103",
  "header": "_header_gwq0h_ccur0_113",
  "header-container": "_header-container_gwq0h_ccur0_116",
  "header-navigation": "_header-navigation_gwq0h_ccur0_123",
  "content": "_content_gwq0h_ccur0_3",
  "content-default": "_content-default_gwq0h_ccur0_159",
  "content-footer": "_content-footer_gwq0h_ccur0_162",
  "content-expanded": "_content-expanded_gwq0h_ccur0_165",
  "focusable": "_focusable_gwq0h_ccur0_169"
};
  