
    import './styles.scoped.css';
    export default {
  "dropdown": "_dropdown_qwoo0_hu9i3_3",
  "awsui-motion-fade-in-dropdown": "_awsui-motion-fade-in-dropdown_qwoo0_hu9i3_1",
  "root": "_root_qwoo0_hu9i3_25",
  "interior": "_interior_qwoo0_hu9i3_36",
  "root-inline": "_root-inline_qwoo0_hu9i3_40",
  "stretch": "_stretch_qwoo0_hu9i3_43",
  "dropdown-drop-up": "_dropdown-drop-up_qwoo0_hu9i3_71",
  "with-limited-width": "_with-limited-width_qwoo0_hu9i3_74",
  "dropdown-drop-left": "_dropdown-drop-left_qwoo0_hu9i3_77",
  "dropdown-drop-right": "_dropdown-drop-right_qwoo0_hu9i3_80",
  "occupy-entire-width": "_occupy-entire-width_qwoo0_hu9i3_83",
  "hide-upper-border": "_hide-upper-border_qwoo0_hu9i3_86",
  "open": "_open_qwoo0_hu9i3_92",
  "nowrap": "_nowrap_qwoo0_hu9i3_95",
  "ie11-wrapper": "_ie11-wrapper_qwoo0_hu9i3_101"
};
  