import React from 'react';
import Parameter from './Parameter';
import Container from '@amzn/awsui-components-react/polaris/container';

interface ParameterGroupProps {
  name: string;
  parameters: JSX.Element[];
}

export default function ParameterGroup({
  name,
  parameters,
}: ParameterGroupProps): JSX.Element {
  return <Container header={name}>{parameters}</Container>;
}
