
    import './styles.scoped.css';
    export default {
  "arrow": "_arrow_xjuzf_f98th_5",
  "arrow-outer": "_arrow-outer_xjuzf_f98th_9",
  "arrow-inner": "_arrow-inner_xjuzf_f98th_9",
  "arrow-position-right-top": "_arrow-position-right-top_xjuzf_f98th_40",
  "arrow-position-right-bottom": "_arrow-position-right-bottom_xjuzf_f98th_40",
  "arrow-position-left-top": "_arrow-position-left-top_xjuzf_f98th_43",
  "arrow-position-left-bottom": "_arrow-position-left-bottom_xjuzf_f98th_43",
  "arrow-position-top-center": "_arrow-position-top-center_xjuzf_f98th_46",
  "arrow-position-top-responsive": "_arrow-position-top-responsive_xjuzf_f98th_46",
  "arrow-position-bottom-center": "_arrow-position-bottom-center_xjuzf_f98th_49",
  "arrow-position-bottom-responsive": "_arrow-position-bottom-responsive_xjuzf_f98th_49",
  "body": "_body_xjuzf_f98th_55",
  "variant-annotation": "_variant-annotation_xjuzf_f98th_74",
  "body-size-small": "_body-size-small_xjuzf_f98th_79",
  "fixed-width": "_fixed-width_xjuzf_f98th_82",
  "body-size-medium": "_body-size-medium_xjuzf_f98th_86",
  "body-size-large": "_body-size-large_xjuzf_f98th_93",
  "has-dismiss": "_has-dismiss_xjuzf_f98th_100",
  "dismiss": "_dismiss_xjuzf_f98th_105",
  "dismiss-control": "_dismiss-control_xjuzf_f98th_112",
  "header": "_header_xjuzf_f98th_116",
  "content": "_content_xjuzf_f98th_132",
  "content-overflow-visible": "_content-overflow-visible_xjuzf_f98th_140",
  "container": "_container_xjuzf_f98th_144",
  "container-arrow": "_container-arrow_xjuzf_f98th_152",
  "container-arrow-position-right-top": "_container-arrow-position-right-top_xjuzf_f98th_156",
  "container-arrow-position-right-bottom": "_container-arrow-position-right-bottom_xjuzf_f98th_156",
  "container-arrow-position-left-top": "_container-arrow-position-left-top_xjuzf_f98th_168",
  "container-arrow-position-left-bottom": "_container-arrow-position-left-bottom_xjuzf_f98th_168",
  "container-arrow-position-top-center": "_container-arrow-position-top-center_xjuzf_f98th_180",
  "container-arrow-position-top-responsive": "_container-arrow-position-top-responsive_xjuzf_f98th_180",
  "container-arrow-position-bottom-center": "_container-arrow-position-bottom-center_xjuzf_f98th_188",
  "awsui-motion-fade-in": "_awsui-motion-fade-in_xjuzf_f98th_1",
  "root": "_root_xjuzf_f98th_217",
  "trigger": "_trigger_xjuzf_f98th_228",
  "trigger-type-text": "_trigger-type-text_xjuzf_f98th_233"
};
  