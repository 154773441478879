import React from 'react';

import { Box } from '@amzn/awsui-components-react/polaris';
import './styling/KpiStyle.css';

export interface Kpi {
  name: string;
  value: number;
  unit: string;
}

interface KpiContainerProps {
  kpis: Array<Kpi>;
}

export default (props: KpiContainerProps) => {
  const kpis = props.kpis.map(kpi => (
    <div>
      <Box textAlign="center" fontSize="heading-xl" fontWeight="light">
        Estimated <strong>{kpi.name}</strong>:{' '}
        <span className="kpiValueStyle">{kpi.value}</span> {kpi.unit}
      </Box>
    </div>
  ));

  return <div>{kpis}</div>;
};
