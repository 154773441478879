import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Alert, Spinner } from '@amzn/awsui-components-react';
import Button from '@amzn/awsui-components-react/polaris/button';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { ErrorBoundary } from './ErrorBoundary';
import Configurator from './Configurator';
import ManifestSelector from './ManifestSelector';
import Help from './Help';

enum AppStage {
  LoggingIn = 'LOGGING_IN',
  Redirecting = 'REDIRECTING',
  LoggedIn = 'LOGGED_IN',
}

/**
 * State for the main application component
 * @property appStage the current login stage
 * @property user current user
 * @property toolsOpen whether the tools window should be opened
 */
export interface State {
  appStage: string;
  user: CognitoUserInterface | null;
  toolsOpen: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default class App extends Component<{}, State> {
  // initialize default state
  state = {
    appStage: AppStage.LoggingIn,
    user: null,
    toolsOpen: true,
  } as State;

  componentDidMount(): void {
    console.log('Current App stage: ', this.state.appStage);
    console.log('Attempting To Retrieve Cognito User From Stored State...');

    // if CognitoUser hasn't been set, attempt to retrieve authenticated user
    if (this.state.user == null) {
      console.log('User Not Set. Getting User... ');

      // get current authenticated user
      Auth.currentAuthenticatedUser()
        .then((user: CognitoUserInterface) => {
          console.log('User Found: ', user);
          this.setState({ user });

          this.setAppStage(AppStage.LoggedIn);
          console.log('Current App stage: ', this.state.appStage);
        })
        .catch(error => {
          // authenticated user was not detected, attempting new authentication
          console.log('No Authenticated User Available:', error);
          console.log('Now Redirecting To Midway...');
          this.setAppStage(AppStage.Redirecting);
        });
    }
  }

  /**
   * Helper method for setting the app stage of the app.
   * @param appStage: the current login stage
   */
  setAppStage(appStage: string) {
    this.setState({ appStage });
  }

  render() {
    const app = (
      <ErrorBoundary>
        {this.state.appStage === AppStage.LoggingIn && (
          <Alert header={'Logging In'}>
            <Spinner size={'normal'} />
            One moment...
          </Alert>
        )}
        {this.state.appStage === AppStage.Redirecting && (
          <Button
            iconAlign="right"
            iconName="external"
            onClick={() =>
              Auth.federatedSignIn({ customProvider: 'FederateOIDC' })
            }
          >
            Sign in with Amazon Federate
          </Button>
        )}
        {this.state.appStage === AppStage.LoggedIn && (
          <Router>
            <Switch>
              <Route path="/:manifestId" children={<Configurator />} />
              <Route path="/">
                <ManifestSelector />
              </Route>
            </Switch>
          </Router>
        )}
      </ErrorBoundary>
    );

    return (
      <AppLayout
        navigationHide={true}
        tools={<Help />}
        toolsOpen={this.state.toolsOpen}
        onToolsChange={_ =>
          this.setState(prev => ({ ...prev, toolsOpen: !prev.toolsOpen }))
        }
        content={app}
      ></AppLayout>
    );
  }
}
