import React from 'react';
import ReactMarkdown from 'react-markdown';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import TextContent from '@amzn/awsui-components-react/polaris/grid';

import Slider from './Slider';

interface ParameterProps {
  name: string;
  description: string;
  inputs: Input[];
}

interface Input {
  name: string;
  values: number[];
  default: number;
  isPercentage: boolean;
  selectedValue: number;
  onValueChanged: (value: number) => void;
}

export default function Parameter(props: ParameterProps): JSX.Element {
  const sliders = props.inputs.map(input => {
    return (
      <Slider
        key={input.name}
        isPercentage={input.isPercentage}
        values={input.values}
        defaultValue={input.default}
        selectedValue={input.selectedValue}
        onValueChanged={input.onValueChanged}
      />
    );
  });

  return (
    <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 5 }]}>
      <TextContent>
        <h4>{props.name}</h4>
      </TextContent>
      <TextContent>
        <ReactMarkdown>{props.description}</ReactMarkdown>
      </TextContent>
      <div>{sliders}</div>
    </Grid>
  );
}
