import React from 'react';
import {
  Button,
  Popover,
  StatusIndicator,
} from '@amzn/awsui-components-react/polaris';

export default () => (
  <Popover
    size="small"
    position="top"
    triggerType="custom"
    dismissButton={false}
    content={
      <StatusIndicator type="success">Configuration copied</StatusIndicator>
    }
  >
    <Button
      iconName="copy"
      onClick={() => {
        void navigator.clipboard.writeText(window.location.toString());
      }}
    >
      Copy
    </Button>
  </Popover>
);
