
    import './styles.scoped.css';
    export default {
  "segment": "_segment_8cbea_haisk_3",
  "disabled": "_disabled_8cbea_haisk_64",
  "selected": "_selected_8cbea_haisk_82",
  "icon": "_icon_8cbea_haisk_100",
  "with-text": "_with-text_8cbea_haisk_104",
  "with-no-text": "_with-no-text_8cbea_haisk_110",
  "root": "_root_8cbea_haisk_117",
  "segment-part": "_segment-part_8cbea_haisk_127",
  "select": "_select_8cbea_haisk_82",
  "segment-count-2": "_segment-count-2_8cbea_haisk_146",
  "segment-count-3": "_segment-count-3_8cbea_haisk_151",
  "segment-count-4": "_segment-count-4_8cbea_haisk_156",
  "segment-count-5": "_segment-count-5_8cbea_haisk_161",
  "segment-count-6": "_segment-count-6_8cbea_haisk_166"
};
  