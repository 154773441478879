
    import './styles.scoped.css';
    export default {
  "root": "_root_l0dv0_1imfv_3",
  "header": "_header_l0dv0_1imfv_16",
  "header-link": "_header-link_l0dv0_1imfv_21",
  "list": "_list_l0dv0_1imfv_28",
  "list-variant-root": "_list-variant-root_l0dv0_1imfv_34",
  "list-variant-expandable-link-group": "_list-variant-expandable-link-group_l0dv0_1imfv_40",
  "list-item": "_list-item_l0dv0_1imfv_44",
  "section": "_section_l0dv0_1imfv_50",
  "expandable-link-group": "_expandable-link-group_l0dv0_1imfv_51",
  "section-header": "_section-header_l0dv0_1imfv_63",
  "link": "_link_l0dv0_1imfv_68",
  "link-active": "_link-active_l0dv0_1imfv_73",
  "info": "_info_l0dv0_1imfv_103",
  "external-icon": "_external-icon_l0dv0_1imfv_107",
  "divider": "_divider_l0dv0_1imfv_111",
  "divider-default": "_divider-default_l0dv0_1imfv_117",
  "divider-header": "_divider-header_l0dv0_1imfv_121"
};
  