import React from 'react';
import { useParams } from 'react-router-dom';

import ParameterConfigurator from './ParameterConfigurator';

export default function Configurator(): JSX.Element {
  const { manifestId } = useParams<{ manifestId: string }>();

  console.log(`loading configurator for ${manifestId}`);

  return <ParameterConfigurator manifestId={manifestId} />;
}
