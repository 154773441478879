
    import './styles.scoped.css';
    export default {
  "root": "_root_wih1l_1vath_3",
  "tools": "_tools_wih1l_1vath_11",
  "tools-filtering": "_tools-filtering_wih1l_1vath_17",
  "tools-align-right": "_tools-align-right_wih1l_1vath_21",
  "tools-pagination": "_tools-pagination_wih1l_1vath_25",
  "tools-preferences": "_tools-preferences_wih1l_1vath_25",
  "tools-small": "_tools-small_wih1l_1vath_31",
  "table": "_table_wih1l_1vath_37",
  "table-layout-fixed": "_table-layout-fixed_wih1l_1vath_41",
  "wrapper": "_wrapper_wih1l_1vath_45",
  "cell": "_cell_wih1l_1vath_51",
  "header-cell": "_header-cell_wih1l_1vath_56",
  "header-cell-sticky": "_header-cell-sticky_wih1l_1vath_65",
  "header-cell-sortable": "_header-cell-sortable_wih1l_1vath_71",
  "body-cell": "_body-cell_wih1l_1vath_84",
  "body-cell-wrap": "_body-cell-wrap_wih1l_1vath_89",
  "row": "_row_wih1l_1vath_102",
  "row-selected": "_row-selected_wih1l_1vath_105",
  "cell-merged": "_cell-merged_wih1l_1vath_120",
  "cell-merged-content": "_cell-merged-content_wih1l_1vath_124",
  "empty": "_empty_wih1l_1vath_131",
  "loading": "_loading_wih1l_1vath_135",
  "selection-control": "_selection-control_wih1l_1vath_139",
  "sorting-icon": "_sorting-icon_wih1l_1vath_150",
  "header-cell-disabled": "_header-cell-disabled_wih1l_1vath_157",
  "header-cell-sorted": "_header-cell-sorted_wih1l_1vath_157",
  "header-cell-content": "_header-cell-content_wih1l_1vath_157",
  "header-cell-ascending": "_header-cell-ascending_wih1l_1vath_201",
  "header-cell-descending": "_header-cell-descending_wih1l_1vath_202",
  "header-cell-text": "_header-cell-text_wih1l_1vath_206",
  "header-cell-text-wrap": "_header-cell-text-wrap_wih1l_1vath_206",
  "sticky-scrollbar": "_sticky-scrollbar_wih1l_1vath_212",
  "sticky-scrollbar-content": "_sticky-scrollbar-content_wih1l_1vath_222",
  "sticky-scrollbar-visible": "_sticky-scrollbar-visible_wih1l_1vath_225",
  "header-secondary": "_header-secondary_wih1l_1vath_229",
  "header-controls": "_header-controls_wih1l_1vath_242",
  "thead-active": "_thead-active_wih1l_1vath_249"
};
  