
    import './styles.scoped.css';
    export default {
  "segment": "_segment_1edmh_o2yla_3",
  "segment__hover": "_segment__hover_1edmh_o2yla_30",
  "label": "_label_1edmh_o2yla_44",
  "root": "_root_1edmh_o2yla_58",
  "filter-container": "_filter-container_1edmh_o2yla_69",
  "has-default-filter": "_has-default-filter_1edmh_o2yla_73",
  "pie": "_pie_1edmh_o2yla_77",
  "content": "_content_1edmh_o2yla_84",
  "content--small": "_content--small_1edmh_o2yla_90",
  "content--medium": "_content--medium_1edmh_o2yla_93",
  "content--large": "_content--large_1edmh_o2yla_96",
  "content--reserve-filter": "_content--reserve-filter_1edmh_o2yla_99",
  "content--reserve-legend": "_content--reserve-legend_1edmh_o2yla_102",
  "status-container": "_status-container_1edmh_o2yla_106",
  "chart-container": "_chart-container_1edmh_o2yla_110",
  "inner-content": "_inner-content_1edmh_o2yla_115",
  "segment__highlight": "_segment__highlight_1edmh_o2yla_129",
  "focus-outline": "_focus-outline_1edmh_o2yla_134",
  "segment--dimmed": "_segment--dimmed_1edmh_o2yla_155",
  "segment--highlighted": "_segment--highlighted_1edmh_o2yla_158",
  "label--dimmed": "_label--dimmed_1edmh_o2yla_173",
  "label--align-right": "_label--align-right_1edmh_o2yla_176",
  "label-text": "_label-text_1edmh_o2yla_180",
  "label__description": "_label__description_1edmh_o2yla_185",
  "label-line": "_label-line_1edmh_o2yla_190",
  "label--highlighted": "_label--highlighted_1edmh_o2yla_194",
  "popover-header": "_popover-header_1edmh_o2yla_198"
};
  