
    import './styles.scoped.css';
    export default {
  "arrow": "_arrow_lm6vo_woarc_3",
  "arrow-outer": "_arrow-outer_lm6vo_woarc_7",
  "arrow-inner": "_arrow-inner_lm6vo_woarc_7",
  "arrow-position-right-top": "_arrow-position-right-top_lm6vo_woarc_38",
  "arrow-position-right-bottom": "_arrow-position-right-bottom_lm6vo_woarc_38",
  "arrow-position-left-top": "_arrow-position-left-top_lm6vo_woarc_41",
  "arrow-position-left-bottom": "_arrow-position-left-bottom_lm6vo_woarc_41",
  "arrow-position-top-center": "_arrow-position-top-center_lm6vo_woarc_44",
  "arrow-position-top-responsive": "_arrow-position-top-responsive_lm6vo_woarc_44",
  "arrow-position-bottom-center": "_arrow-position-bottom-center_lm6vo_woarc_47",
  "arrow-position-bottom-responsive": "_arrow-position-bottom-responsive_lm6vo_woarc_47",
  "root": "_root_lm6vo_woarc_51",
  "layout-wrapper": "_layout-wrapper_lm6vo_woarc_52",
  "close-button": "_close-button_lm6vo_woarc_57",
  "layout": "_layout_lm6vo_woarc_52",
  "layout-no-scroll": "_layout-no-scroll_lm6vo_woarc_70",
  "layout-main": "_layout-main_lm6vo_woarc_74",
  "layout-main-scrollable": "_layout-main-scrollable_lm6vo_woarc_79",
  "unfocusable": "_unfocusable_lm6vo_woarc_84",
  "drawer": "_drawer_lm6vo_woarc_88",
  "drawer-mobile": "_drawer-mobile_lm6vo_woarc_94",
  "drawer-closed": "_drawer-closed_lm6vo_woarc_97",
  "drawer-content": "_drawer-content_lm6vo_woarc_105",
  "toggle": "_toggle_lm6vo_woarc_127",
  "breadcrumbs-desktop": "_breadcrumbs-desktop_lm6vo_woarc_132",
  "content-wrapper": "_content-wrapper_lm6vo_woarc_136",
  "content-wrapper-mobile": "_content-wrapper-mobile_lm6vo_woarc_139",
  "notifications-sticky": "_notifications-sticky_lm6vo_woarc_147",
  "announcement-popover-target": "_announcement-popover-target_lm6vo_woarc_153",
  "navigation-toggle": "_navigation-toggle_lm6vo_woarc_159",
  "tools-toggle": "_tools-toggle_lm6vo_woarc_163",
  "navigation": "_navigation_lm6vo_woarc_159",
  "navigation-close": "_navigation-close_lm6vo_woarc_175",
  "tools": "_tools_lm6vo_woarc_163",
  "tools-close": "_tools-close_lm6vo_woarc_187",
  "notifications": "_notifications_lm6vo_woarc_147",
  "breadcrumbs": "_breadcrumbs_lm6vo_woarc_132",
  "content": "_content_lm6vo_woarc_136"
};
  