import React, { useState } from 'react';
import Input from '@amzn/awsui-components-react/polaris/input';

/**
 * Component that allows the user to search for and select a configurator-enabled manifest.
 *
 * Upon selection, the component redirects the page to the selected configurator.
 */
export default () => {
  const [value, setValue] = useState('');

  return (
    <Input
      type="search"
      onChange={({ detail }) => setValue(detail.value)}
      value={value}
      placeholder="Enter model family"
      onKeyDown={({ detail }) => {
        if (detail.key === 'Enter') {
          window.location.href = `/${value}`;
        }
      }}
    />
  );
};
