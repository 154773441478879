import { API } from 'aws-amplify';
import {
  Record,
  Boolean,
  String,
  Array,
  Number,
  Static,
  Optional,
} from 'runtypes';

/**
 * Describes an input as retrieved from the parameter lambda. This is later
 * translated into a slider.
 */
const InputDefinition = Record({
  name: String,
  default: Number,
  type: String,
  min: Number,
  max: Number,
  step: Number,
});

/**
 * Describes the structure of a parameter entry from the parameter lambda.
 */
const ParameterDefinition = Record({
  name: String,
  humanName: String,
  group: String,
  description: String,
  isPercentage: Optional(Boolean),
  inputs: Array(InputDefinition),
});
type ParameterDefinition = Static<typeof ParameterDefinition>;

const ParameterSchema = Record({
  outputs: Array(ParameterDefinition),
  parameters: Array(ParameterDefinition),
});

const ParameterLambdaResponse = Record({
  displaySchema: ParameterSchema,
  family: String,
  version: Optional(String),
});
export type ParameterLambdaResponse = Static<typeof ParameterLambdaResponse>;

/**
 * Retrieve the parameter and output definitions for a given manifest ID from the model API.
 */
export async function fetchParameters(
  username: string,
  manifestId: string,
): Promise<ParameterLambdaResponse> {
  return API.get('modelApi', `/${manifestId}/parameters`, {
    queryStringParameters: { requester: username },
  }).then(res => ParameterLambdaResponse.check(res));
}

function getValueInRange(inputVal: number, minVal: number, maxVal: number) {
  return Math.min(Math.max(inputVal, minVal), maxVal);
}

/**
 * Provides various helper views onto the parameter definitions.
 */
export class ParameterRepository {
  public definitions: ParameterDefinition[];

  constructor(definitions: ParameterDefinition[]) {
    this.definitions = definitions;
  }

  getDefaultValues(): Map<string, number> {
    return new Map(
      this.definitions
        .flatMap(def => def.inputs)
        .map(input => [
          input.name,
          getValueInRange(input.default, input.min, input.max),
        ]),
    );
  }

  /**
   * Returns the parameter definitions grouped by their group name.
   */
  getByGroup(): Map<string, ParameterDefinition[]> {
    const paramsByGroup = new Map<string, ParameterDefinition[]>();

    for (const param of this.definitions) {
      if (paramsByGroup.get(param.group) === undefined) {
        paramsByGroup.set(param.group, []);
      }

      paramsByGroup.get(param.group)!.push(param);
    }

    return paramsByGroup;
  }
}
