import React, { ErrorInfo } from 'react';

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: any;
}

export class ErrorBoundary extends React.Component<any, State> {
  state = { hasError: false, error: null, errorInfo: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });

    console.log('error', error);
    console.log('errorInfo', errorInfo);

    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  handleRefresh = () => window.location.reload();

  render() {
    const { children } = this.props;
    const { errorInfo } = this.state;

    if (!errorInfo) return children;

    return <div className="error-boundary">Error</div>;
  }
}
