
    import './styles.scoped.css';
    export default {
  "button-trigger": "_button-trigger_18eso_1b6tt_3",
  "arrow": "_arrow_18eso_1b6tt_3",
  "placeholder": "_placeholder_18eso_1b6tt_36",
  "pressed": "_pressed_18eso_1b6tt_49",
  "disabled": "_disabled_18eso_1b6tt_52",
  "invalid": "_invalid_18eso_1b6tt_70"
};
  