import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '@amzn/awsui-global-styles/polaris.css';
import { Amplify } from 'aws-amplify';
import { String, Array, Record } from 'runtypes';

const Settings = Record({
  identityPoolID: String,
  userPoolID: String,
  userPoolWebClientID: String,
  region: String,
  domain: String,
  scope: Array(String),
  responseType: String,
  redirectUrl: String,
  apiEndpoint: String,
});

const settings =
  location.hostname !== 'localhost'
    ? './settings.json'
    : './local-settings.json';

fetch(settings, { credentials: 'include' })
  .then(response => response.json())
  .then(settingsObject => Settings.check(settingsObject))
  .then(settings => {
    const config = {
      oauth: {
        domain: settings.domain,
        scope: settings.scope,
        redirectSignIn: settings.redirectUrl,
        redirectSignOut: settings.redirectUrl,
        responseType: settings.responseType,
      },
      aws_cognito_identity_pool_id: settings.identityPoolID,
      aws_cognito_region: settings.region,
      aws_user_pools_id: settings.userPoolID,
      aws_user_pools_web_client_id: settings.userPoolWebClientID,
      API: {
        endpoints: [
          {
            name: 'modelApi',
            endpoint: `https://${settings.apiEndpoint}`,
            region: settings.region,
          },
        ],
      },
    };
    Amplify.configure(config);

    ReactDOM.render(<App />, document.getElementById('app'));
  })
  .catch(error => console.error('Unable to load settings.json.', error));
